<template>
  <div>

    <div class="text-center">
      <div class="font-weight-bold mb-3 py-md-4">
        <el-card-title :title="$t('user.register.title')" />
      </div>
      <form @submit.prevent="register" class="form row justify-content-center align-items-center">
        <div class="col-md-6 col-xxl-5 mb-3 mb-md-0">
          <img src="@/assets/images/register.svg" class="img-register mb-3">
          <div class="pr-md-2">
            <p class="font-weight-bold mb-3">{{ $t('user.register.text_left') }}</p>
            <p class="text-left"><i class="icon-check text-success"></i> {{ $t('user.register.text_bullet_1') }}</p>
            <p class="text-left"><i class="icon-check text-success"></i> {{ $t('user.register.text_bullet_2') }}</p>
            <p class="text-left"><i class="icon-check text-success"></i> {{ $t('user.register.text_bullet_3') }}</p>
          </div>
        </div>
        <div class="col-md-6 col-xxl-5 text-left">
          <el-alert type="warning" v-if="formError !== false">
            <span v-html="formError"></span>
          </el-alert>
          <div class="form-group mb-3 form-group-feedback form-group-feedback-left">
            <input required autocomplete="email" v-model="email" type="email" id="email" class="form-control" :placeholder="$t('user.register.email')">
            <div class="form-control-feedback">
              <i class="icon-envelop3 text-slate"></i>
            </div>
          </div>
          <div class="form-group mb-3 form-group-feedback form-group-feedback-left">
            <input required autocomplete="company" v-model="company" type="text" id="company" class="form-control" :placeholder="$t('user.register.company')">
            <div class="form-control-feedback">
              <i class="icon-office text-slate"></i>
            </div>
          </div>
          <div class="form-group mb-3 vue-phone-input-wrapper">
            <VuePhoneNumberInput
              ref="userPhoneNumberField"
              v-model="phone_number"
              :default-country-code="currentLocale"
              :preferred-countries="[currentLocale]"
              :placeholder="$t('user.register.phone_number')"
              :translations="$t('common.phone')"
              @update="phoneFieldChanged"
            />
          </div>
          <div class="form-group mb-3 form-group-feedback form-group-feedback-left">
            <input required autocomplete="current-password" v-model="password" type="password" class="form-control" :placeholder="$t('user.register.password')">
            <div class="form-control-feedback">
              <i class="icon-lock2 text-slate"></i>
            </div>
          </div>
          <div class="text-center">
            <button v-if="$store.state.auth.status != 'loading'" type="submit" class="font-weight-semibold w-100 px-3 btn btn-success text-white btn-register">
              <i class="icon-arrow-right8 mr-1"></i> {{ $t('user.register.register') }}
            </button>
            <div v-if="$store.state.auth.status == 'loading'" class="bg-white p-2">
              <loader />
            </div>
          </div>
        </div>
        <div class="my-2 col-12">
          <hr />
          <router-link class="font-weight-semibold border-bottom text-secondary" tag="a" to="/login">{{ $t('user.register.login_link') }}</router-link>
        </div>
      </form>
    </div>

  </div>
</template>

<script>

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { AUTH_REGISTER } from '@/store/mutations-types'

import {
  PARTNERSHIP_VERIFY_TOKEN,
  PARTNERSHIP_UPDATE_CLIENT,
} from '@/../Modules/Partnership/mutations-types'

export default {
  components: {
    VuePhoneNumberInput,
  },
  data () {
    return {
      email: '',
      password: '',
      phone_number: '',
      phoneFormatted: null,
      company: '',
      formError: false,
    }
  },
  mounted () {
  },
  methods: {
    register () {
      this.formError = false
      this.$store.dispatch('auth/' + AUTH_REGISTER, {
        email: this.email,
        password: this.password,
        company: this.company,
        phone_number: this.phoneFormatted,
      }).then(() => {
        this.$router.push('/')
      })
      .catch((errors) => {
        this.formError = ''
        _.toArray(errors.response.data.errors).forEach( (element, index) => {
          this.formError+= '' + element[0] + '<br/>'
        })
      })
    },
    phoneFieldChanged (e) {
      this.phoneFormatted = e.formattedNumber
      if (e.formatNational) {
        this.phone_number = e.formatNational
      }
    },
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale.split('-')[0].toUpperCase()
    }
  },
  beforeCreate (to, from, next) {
    if (typeof this.$route.params.token != "undefined" && this.$route.params.token) {
      this.$store.dispatch('partnership/' + PARTNERSHIP_VERIFY_TOKEN, { token: this.$route.params.token }).then(data => {
        this.email = data.email
        this.company = data.company
        this.data = data
      })
      .catch(error => {
        this.$router.push('/register')
        if(error != false)
          this.notifError(error)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.img-register {
  max-width: 220px;
}
.btn-register {
  font-size: 1.2em;
}
.vue-phone-input-wrapper {
  position: relative;
  border: 2px solid #ddd !important;
  border-radius: 4px;
}
/deep/ .vue-phone-number-input {
  margin: -1px -2px -1px -1px;
  .field-input {
    border: 1px solid #ddd !important;
  }
  .iti-flag {
    position: relative;
    top: -2px;
  }
  .field.vue-input-ui,
  .field-input {
    height: 38px !important;
    min-height: 38px !important;
  }
  #VuePhoneNumberInput_phone_number {
    cursor: text;
  }
  #VuePhoneNumberInput_country_selector {
    border-right: 2px solid #ddd !important;
  }
}
</style>
